exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-addcompany-jsx": () => import("./../../../src/pages/admin/addcompany.jsx" /* webpackChunkName: "component---src-pages-admin-addcompany-jsx" */),
  "component---src-pages-admin-addparticipant-jsx": () => import("./../../../src/pages/admin/addparticipant.jsx" /* webpackChunkName: "component---src-pages-admin-addparticipant-jsx" */),
  "component---src-pages-admin-addpoc-jsx": () => import("./../../../src/pages/admin/addpoc.jsx" /* webpackChunkName: "component---src-pages-admin-addpoc-jsx" */),
  "component---src-pages-admin-approve-company-jsx": () => import("./../../../src/pages/admin/approveCompany.jsx" /* webpackChunkName: "component---src-pages-admin-approve-company-jsx" */),
  "component---src-pages-admin-company-registration-jsx": () => import("./../../../src/pages/admin/Company-registration.jsx" /* webpackChunkName: "component---src-pages-admin-company-registration-jsx" */),
  "component---src-pages-admin-components-admin-data-table-jsx": () => import("./../../../src/pages/admin/components/adminDataTable.jsx" /* webpackChunkName: "component---src-pages-admin-components-admin-data-table-jsx" */),
  "component---src-pages-admin-components-admin-login-popup-jsx": () => import("./../../../src/pages/admin/components/adminLoginPopup.jsx" /* webpackChunkName: "component---src-pages-admin-components-admin-login-popup-jsx" */),
  "component---src-pages-admin-components-approve-company-table-jsx": () => import("./../../../src/pages/admin/components/approve-company-table.jsx" /* webpackChunkName: "component---src-pages-admin-components-approve-company-table-jsx" */),
  "component---src-pages-admin-components-basic-form-admin-register-jsx": () => import("./../../../src/pages/admin/components/basicFormAdminRegister.jsx" /* webpackChunkName: "component---src-pages-admin-components-basic-form-admin-register-jsx" */),
  "component---src-pages-admin-components-basic-form-company-register-jsx": () => import("./../../../src/pages/admin/components/basicFormCompanyRegister.jsx" /* webpackChunkName: "component---src-pages-admin-components-basic-form-company-register-jsx" */),
  "component---src-pages-admin-components-basic-form-poc-register-jsx": () => import("./../../../src/pages/admin/components/basicFormPOCRegister.jsx" /* webpackChunkName: "component---src-pages-admin-components-basic-form-poc-register-jsx" */),
  "component---src-pages-admin-components-company-reg-table-jsx": () => import("./../../../src/pages/admin/components/company-reg-table.jsx" /* webpackChunkName: "component---src-pages-admin-components-company-reg-table-jsx" */),
  "component---src-pages-admin-components-poc-reg-table-jsx": () => import("./../../../src/pages/admin/components/poc-reg-table.jsx" /* webpackChunkName: "component---src-pages-admin-components-poc-reg-table-jsx" */),
  "component---src-pages-admin-components-poclocations-jsx": () => import("./../../../src/pages/admin/components/poclocations.jsx" /* webpackChunkName: "component---src-pages-admin-components-poclocations-jsx" */),
  "component---src-pages-admin-components-race-places-jsx": () => import("./../../../src/pages/admin/components/racePlaces.jsx" /* webpackChunkName: "component---src-pages-admin-components-race-places-jsx" */),
  "component---src-pages-admin-components-super-admin-login-popup-jsx": () => import("./../../../src/pages/admin/components/superAdminLoginPopup.jsx" /* webpackChunkName: "component---src-pages-admin-components-super-admin-login-popup-jsx" */),
  "component---src-pages-admin-datatable-jsx": () => import("./../../../src/pages/admin/datatable.jsx" /* webpackChunkName: "component---src-pages-admin-datatable-jsx" */),
  "component---src-pages-admin-index-jsx": () => import("./../../../src/pages/admin/index.jsx" /* webpackChunkName: "component---src-pages-admin-index-jsx" */),
  "component---src-pages-admin-layout-header-jsx": () => import("./../../../src/pages/admin/layout/Header.jsx" /* webpackChunkName: "component---src-pages-admin-layout-header-jsx" */),
  "component---src-pages-admin-layout-index-jsx": () => import("./../../../src/pages/admin/layout/index.jsx" /* webpackChunkName: "component---src-pages-admin-layout-index-jsx" */),
  "component---src-pages-admin-poc-details-jsx": () => import("./../../../src/pages/admin/POC-Details.jsx" /* webpackChunkName: "component---src-pages-admin-poc-details-jsx" */),
  "component---src-pages-admin-poc-locations-jsx": () => import("./../../../src/pages/admin/POC-Locations.jsx" /* webpackChunkName: "component---src-pages-admin-poc-locations-jsx" */),
  "component---src-pages-admin-racelocations-jsx": () => import("./../../../src/pages/admin/racelocations.jsx" /* webpackChunkName: "component---src-pages-admin-racelocations-jsx" */),
  "component---src-pages-admin-superadmin-jsx": () => import("./../../../src/pages/admin/superadmin.jsx" /* webpackChunkName: "component---src-pages-admin-superadmin-jsx" */),
  "component---src-pages-adminui-bibdetails-jsx": () => import("./../../../src/pages/adminui/bibdetails.jsx" /* webpackChunkName: "component---src-pages-adminui-bibdetails-jsx" */),
  "component---src-pages-adminui-components-admin-data-table-jsx": () => import("./../../../src/pages/adminui/components/adminDataTable.jsx" /* webpackChunkName: "component---src-pages-adminui-components-admin-data-table-jsx" */),
  "component---src-pages-adminui-components-admin-login-popup-jsx": () => import("./../../../src/pages/adminui/components/adminLoginPopup.jsx" /* webpackChunkName: "component---src-pages-adminui-components-admin-login-popup-jsx" */),
  "component---src-pages-adminui-index-jsx": () => import("./../../../src/pages/adminui/index.jsx" /* webpackChunkName: "component---src-pages-adminui-index-jsx" */),
  "component---src-pages-adminui-layout-header-jsx": () => import("./../../../src/pages/adminui/layout/Header.jsx" /* webpackChunkName: "component---src-pages-adminui-layout-header-jsx" */),
  "component---src-pages-adminui-layout-index-jsx": () => import("./../../../src/pages/adminui/layout/index.jsx" /* webpackChunkName: "component---src-pages-adminui-layout-index-jsx" */),
  "component---src-pages-basiccompanyformpage-jsx": () => import("./../../../src/pages/basiccompanyformpage.jsx" /* webpackChunkName: "component---src-pages-basiccompanyformpage-jsx" */),
  "component---src-pages-basicformpage-jsx": () => import("./../../../src/pages/basicformpage.jsx" /* webpackChunkName: "component---src-pages-basicformpage-jsx" */),
  "component---src-pages-company-addparticipant-jsx": () => import("./../../../src/pages/company/addparticipant.jsx" /* webpackChunkName: "component---src-pages-company-addparticipant-jsx" */),
  "component---src-pages-company-components-basic-form-participant-register-jsx": () => import("./../../../src/pages/company/components/basicFormParticipantRegister.jsx" /* webpackChunkName: "component---src-pages-company-components-basic-form-participant-register-jsx" */),
  "component---src-pages-company-components-company-data-table-jsx": () => import("./../../../src/pages/company/components/companyDataTable.jsx" /* webpackChunkName: "component---src-pages-company-components-company-data-table-jsx" */),
  "component---src-pages-company-components-company-login-popup-jsx": () => import("./../../../src/pages/company/components/companyLoginPopup.jsx" /* webpackChunkName: "component---src-pages-company-components-company-login-popup-jsx" */),
  "component---src-pages-company-components-company-reg-table-jsx": () => import("./../../../src/pages/company/components/company-reg-table.jsx" /* webpackChunkName: "component---src-pages-company-components-company-reg-table-jsx" */),
  "component---src-pages-company-components-data-table-import-jsx": () => import("./../../../src/pages/company/components/DataTableImport.jsx" /* webpackChunkName: "component---src-pages-company-components-data-table-import-jsx" */),
  "component---src-pages-company-components-import-file-jsx": () => import("./../../../src/pages/company/components/importFile.jsx" /* webpackChunkName: "component---src-pages-company-components-import-file-jsx" */),
  "component---src-pages-company-datatable-jsx": () => import("./../../../src/pages/company/datatable.jsx" /* webpackChunkName: "component---src-pages-company-datatable-jsx" */),
  "component---src-pages-company-index-jsx": () => import("./../../../src/pages/company/index.jsx" /* webpackChunkName: "component---src-pages-company-index-jsx" */),
  "component---src-pages-company-layout-header-jsx": () => import("./../../../src/pages/company/layout/Header.jsx" /* webpackChunkName: "component---src-pages-company-layout-header-jsx" */),
  "component---src-pages-company-layout-index-jsx": () => import("./../../../src/pages/company/layout/index.jsx" /* webpackChunkName: "component---src-pages-company-layout-index-jsx" */),
  "component---src-pages-company-upload-jsx": () => import("./../../../src/pages/company/upload.jsx" /* webpackChunkName: "component---src-pages-company-upload-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-downloadcertificate-jsx": () => import("./../../../src/pages/downloadcertificate.jsx" /* webpackChunkName: "component---src-pages-downloadcertificate-jsx" */),
  "component---src-pages-failed-jsx": () => import("./../../../src/pages/failed.jsx" /* webpackChunkName: "component---src-pages-failed-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-fbh-jsx": () => import("./../../../src/pages/fbh.jsx" /* webpackChunkName: "component---src-pages-fbh-jsx" */),
  "component---src-pages-feb-2023-jsx": () => import("./../../../src/pages/feb2023.jsx" /* webpackChunkName: "component---src-pages-feb-2023-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-gkrinception-jsx": () => import("./../../../src/pages/gkrinception.jsx" /* webpackChunkName: "component---src-pages-gkrinception-jsx" */),
  "component---src-pages-globalrun-jsx": () => import("./../../../src/pages/globalrun.jsx" /* webpackChunkName: "component---src-pages-globalrun-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kanhashanthivanam-jsx": () => import("./../../../src/pages/kanhashanthivanam.jsx" /* webpackChunkName: "component---src-pages-kanhashanthivanam-jsx" */),
  "component---src-pages-myregisterationlist-jsx": () => import("./../../../src/pages/myregisterationlist.jsx" /* webpackChunkName: "component---src-pages-myregisterationlist-jsx" */),
  "component---src-pages-nov-2023-jsx": () => import("./../../../src/pages/nov2023.jsx" /* webpackChunkName: "component---src-pages-nov-2023-jsx" */),
  "component---src-pages-participant-details-jsx": () => import("./../../../src/pages/participantDetails.jsx" /* webpackChunkName: "component---src-pages-participant-details-jsx" */),
  "component---src-pages-paymentcheckout-jsx": () => import("./../../../src/pages/paymentcheckout.jsx" /* webpackChunkName: "component---src-pages-paymentcheckout-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */),
  "component---src-pages-racecitylocations-jsx": () => import("./../../../src/pages/racecitylocations.jsx" /* webpackChunkName: "component---src-pages-racecitylocations-jsx" */),
  "component---src-pages-raceinfo-jsx": () => import("./../../../src/pages/raceinfo.jsx" /* webpackChunkName: "component---src-pages-raceinfo-jsx" */),
  "component---src-pages-racelocations-jsx": () => import("./../../../src/pages/racelocations.jsx" /* webpackChunkName: "component---src-pages-racelocations-jsx" */),
  "component---src-pages-raceprice-jsx": () => import("./../../../src/pages/raceprice.jsx" /* webpackChunkName: "component---src-pages-raceprice-jsx" */),
  "component---src-pages-specialdiscounts-jsx": () => import("./../../../src/pages/specialdiscounts.jsx" /* webpackChunkName: "component---src-pages-specialdiscounts-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-successfulparticipants-jsx": () => import("./../../../src/pages/successfulparticipants.jsx" /* webpackChunkName: "component---src-pages-successfulparticipants-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-titlesponsor-jsx": () => import("./../../../src/pages/titlesponsor.jsx" /* webpackChunkName: "component---src-pages-titlesponsor-jsx" */),
  "component---src-pages-waitingcontent-jsx": () => import("./../../../src/pages/waitingcontent.jsx" /* webpackChunkName: "component---src-pages-waitingcontent-jsx" */),
  "component---src-pages-winners-jsx": () => import("./../../../src/pages/winners.jsx" /* webpackChunkName: "component---src-pages-winners-jsx" */)
}

